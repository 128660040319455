@import 'antd/dist/antd.css';

.expense_table {
  border-color: #333 !important;
}

.expense_table thead > tr > th{
  border-top: 1px solid #333;
  padding: 10px;
}
.expense_table tr > th{
  border-color: #333 !important;
  padding: 10px;
}

.expense_table tr > td{
  border-color: #333 !important;
  padding: 10px;
}

.report-table .ant-table tr th.column_item {
  background: #C6C6C6 !important;
  padding: 10px;
}

.report-table .ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 0;
  border-left: 1px solid #333;
}

.report-table table > tbody > tr> td:first-child {
  border-top-left-radius: 0;
  border-left: 1px solid #333 !important;
}

.report-table .ant-table.ant-table-bordered > .ant-table-footer {
  border: 1px solid #333;
  border-top: none;
  background: #fff;
  padding: 5px;
}

.expense_table .ant-table-footer {
  padding: 0;
}

.expense_phone .ant-table-thead > tr > th {
  padding: 0;
}

.expense_phone .ant-table-tbody > tr > td{
  padding: 0;
}

.expense_phone{
  padding: 20px  20px  0px  20px;
}

.expense_page {
  padding: 10px;
  padding-top: 40px;
}

.table .ant-table tr{
  font-size: 0.14rem;
}

.table  .column_item{
  font-size: 0.14rem;
}

.blankImg {
  width: 170mm;
  height: 106mm;
  border: 1px dashed #999;
  margin: 0 auto;
  line-height: 106mm;
  font-size: 16px;
}

.phoneBottom{
  font-size:0.16rem,
}

.pcBottom{
  font-size:1rem,
}

.pcText{
  font-size:0.6rem,
}

.phoneImg{
  width:25%;
}

.isPcMx8{
  margin-right: 8px;
}

.isPcNone{
  display: none;
}

.isPhoneNone{
  display: none;
}

.page-footer .ant-divider-horizontal{
  margin: 0;
}

.imgBig{
  transform: rotate(270deg);
}

.ant-layout-header{
  padding: 0;
}

.expense_share_page .react-pdf__Page__canvas{
  margin: 0 auto;
  width: auto !important;
}
.expense_share_page.ant-layout{
  overflow-x: hidden;
}
.expense_share_page_Phone .react-pdf__Page__canvas{
  margin: 0 auto;
  width: 100% !important;
}
.expense_share_page_Phone.ant-layout{
  overflow-x: hidden;
}


.document-header {
  background: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
}

.document-header .ant-col {
  border: 1px solid #333;
  border-left: none;
  border-top: none;
  padding: 10px;
}

.document-header .ant-row > .ant-col:first-of-type {
  border-left: 1px solid #333;
}

.document-header .ant-row:first-of-type {
  border-top: 1px solid #333;
}